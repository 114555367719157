import React from 'react';
import { Button } from 'zent';
import { FormattedMessage } from 'react-intl';
import { checkIsGoodSoldOut } from 'helper/goods';
import { GOODS_STATUS_SELLING, GOODS_FEATURE_TYPE } from 'const';
import { HtPrice } from 'cpn/goods-price';
import { ActivityStatusType } from 'const/group-buying';

import { Style } from './style';
import { useRequireLogin } from 'cpn/require-login-wrapper';


export const GoodsBuyButton = ({ goodsDetail, onGroupBuying, currency, cart }) => {
  const isSoldOut = checkIsGoodSoldOut(goodsDetail.variants) || goodsDetail?.goodsStatus === GOODS_STATUS_SELLING.DOWN;
  const groupActivityDescription = goodsDetail?.groupActivityDescription ?? {};
  const activityStatus = goodsDetail?.activityStatus;
  const isExpired = activityStatus === ActivityStatusType.Expired; // 获取是否过期
  const originPrice = cart?.variantId ? cart.compareAtPrice : goodsDetail?.compareAtPrice; // 原价
  const curPrice = cart?.variantId ? cart.price : goodsDetail?.minPrice; // 拼团价

  const { checkedRequireLogin } = useRequireLogin();

  // 是否为服务商品
  const isServiceGoods = goodsDetail?.goodsFeatureType === GOODS_FEATURE_TYPE.newService;

  const onBuyAlone = () => {
    window.location.href = `https://${window.location.host}/products/${goodsDetail?.handle}`;
  };

  const onGroupBuy = () => {
    const activityId = groupActivityDescription?.activityId;
    onGroupBuying({
      activityId,
    });
  };

  // 服务商品暂不支持
  if (isServiceGoods) {
    return (
      <div className='goods-buy-button'>
        <Button type='primary' className='goods-buy-button-2 operate-button only-one-button' disabled>
          <div className='guide-text'>
            <FormattedMessage id='9798e8f7f61b4de19e4fd5bfb80af4f5' defaultMessage='暂不支持预订' />
          </div>
        </Button>
      </div>
    )
  }

  return (
    <Style>
      {/* 活动没过期且商品有库存 */}
      {!isSoldOut && !isExpired && (
        <div className='goods-buy-button'>
          <Button className='theme-sub operate-button' onClick={checkedRequireLogin(onBuyAlone)}>
            <div className='guide-text'>
              <FormattedMessage id='614e16cbb6744408af33ffb41ca17a66' defaultMessage='单独购买' />
            </div>
            <HtPrice price={originPrice} currency={currency} />
          </Button>

          <Button type='primary' className='theme-primary goods-buy-button-2 operate-button' onClick={checkedRequireLogin(onGroupBuy)}>
            <div className='guide-text'>
              <FormattedMessage id='f0b04aadc77040e69b644ef1d3e2c0ca' defaultMessage='组团购买' />
            </div>
            <HtPrice price={curPrice} currency={currency} />
          </Button>
        </div>
      )}

      {/* 活动过期了 */}
      {isExpired && !isSoldOut && (
        <div className='goods-buy-button'>
          <Button className='theme-sub operate-button only-one-button' onClick={checkedRequireLogin(onBuyAlone)}>
            <div className='guide-text'>
              <FormattedMessage id='614e16cbb6744408af33ffb41ca17a66' defaultMessage='单独购买' />
              <HtPrice price={originPrice} currency={currency} />
            </div>
          </Button>
        </div>
      )}

      {/* 商品售罄 */}
      {isSoldOut && (
        <div className='goods-buy-button'>
          <Button type='primary' className='goods-buy-button-2 operate-button only-one-button' disabled>
            <div className='guide-text'>
              <FormattedMessage id='goods.sold_out' defaultMessage='售罄' />
            </div>
          </Button>
        </div>
      )}
    </Style>
  );
};
