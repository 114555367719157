import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NumberInput } from 'zent';
// import { ShareButtons } from 'cpn/share-buttons';
import SkuList from 'cpn/sku-list';
import { GoodsBuyButton } from '../goods-button';
import { GroupBuyingEntry } from '../group-buying-entry';
import { GroupDesc } from '../../../components/group-desc';
import { GoodsVendor } from 'components/page/goods-detail/goods-vendor';
import { GoodsTitle } from 'components/page/goods-detail/goods-title';
import { ServiceSupport } from 'components/page/goods-detail/service-support';
import { GroupRule } from 'components/page/group-buying/group-rule';
import { ShareButtons } from 'components/page/share-buttons';
import { ActivityStatus } from 'const/group-buying';
import { ReviewsBar } from 'components/page/goods-detail/goods-layout/reviews/review-bar';
import { Wish } from 'components/page/goods-detail/wish';
import { POSTER_TYPE } from '../../../../../../server/constants/poster';
import { useShareLink } from '../../hooks/index';
import { PickupInfo } from 'components/page/goods-detail/pick-up-info';
import { useGoodsLimit } from 'hook/goods/use-goods-limit';

import cn from 'classnames';
import { Style } from './style';

export const ProductView = (props) => {
  const intl = useIntl();
  const {
    kdtId,
    currency,

    baseConfigInfo,
    socialShareData,

    goodsDetail = {},
    cartHook,
    wishHook,
    reviewHook,
    userInfo,
    history,
    activityId,
    goodsId,
    groupBuyingDetail,
  } = props;

  const { shareLinkUrl } = useShareLink({
    activityId,
    goodsId,
    kdtId,
  });
  const isGoodsSoldOut = !goodsDetail?.quantity;

  const { customerId } = userInfo;
  const { cart, onNumChange, onGroupBuying, onSkuSelect } = cartHook;
  const { showReview, reviewOverview, reviewTotalCount } = reviewHook;

  const { wishActionLoading, wishStatus, toggleToWishList } = wishHook;

  const productNameSize = baseConfigInfo?.product_name_size;
  const showSales = baseConfigInfo?.show_sales;

  const { title, variants, withVariants, options = [], limitInfo } = goodsDetail;
  const maxCartNum = cart?.variantId ? cart?.inventory : Infinity;

  // 商品限购
  const { maxGoodsNum, limitTips } = useGoodsLimit({
    intl,
    inventory: maxCartNum,
    limitInfo,
  });
  const limitTipsEle = limitTips && maxGoodsNum !== 0 ? <div className='limit-tips'>{limitTips}</div> : null;

  return (
    <Style className={cn({ 'is-sold-out': isGoodsSoldOut })}>
      <GoodsTitle title={title} productNameSize={productNameSize} />
      <GoodsVendor vendor={goodsDetail?.vendor} />
      <div className='col-item'>
        <ReviewsBar
          reviewOverview={reviewOverview}
          reviewTotalCount={reviewTotalCount}
          soldNum={goodsDetail?.soldNum}
          showSales={showSales}
          showReview={showReview}
        />
        <Wish wishStatus={wishStatus} wishActionLoading={wishActionLoading} toggleToWishList={toggleToWishList} />
      </div>

      <GroupDesc goodsDetail={goodsDetail} currency={currency} cart={cart} />
      <SkuList
        cart={cart}
        options={options}
        variants={variants}
        onSkuSelect={onSkuSelect}
        withVariants={withVariants}
        isNeedDisabled={isGoodsSoldOut || groupBuyingDetail.status === ActivityStatus.ActivityEnd}
      />

      <div className='sku-wrapper'>
        <span className='sku-title'>
          <FormattedMessage id='common_count' />
        </span>
        <div className='goods-sku-detail'>
          {/* 限制为整数 */}
          <NumberInput
            className='custom-number-input'
            value={cart.quantity}
            showCounter
            max={maxCartNum}
            min={1}
            onChange={onNumChange}
            disabled={isGoodsSoldOut || groupBuyingDetail.status === ActivityStatus.ActivityEnd}
          />
          {limitTipsEle}
        </div>
      </div>
      <GroupBuyingEntry
        goodsId={goodsDetail?.goodsId}
        customerId={customerId}
        currency={currency}
        goodsDetail={goodsDetail}
        onGroupBuying={onGroupBuying}
      />
      <GoodsBuyButton
        goodsDetail={goodsDetail}
        onGroupBuying={onGroupBuying}
        currency={currency}
        history={history}
        cart={cart}
      />
      <ShareButtons
        // 生成海报的参数
        postInfo={{
          goodsId,
          activityId,
          posterType: POSTER_TYPE.wxGoodsGroupBuying,
        }}
        shareThirdUrl={`group-buying/product?goodsId=${goodsId}&activityId=${activityId}`}
        copyUrl={shareLinkUrl}
        history={history}
        customClass='goods-detail-share-btns'
        socialShareData={socialShareData}
      />
      <div className='space'>
        <ServiceSupport>
          <GroupRule />
          <div className='space' style={{ marginBottom: 24 }}>
            <PickupInfo goodsId={goodsDetail?.goodsId} />
          </div>
        </ServiceSupport>
      </div>
    </Style>
  );
};
